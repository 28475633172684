import { css } from "@emotion/css";
import { colorScales, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MouseEventHandler } from "react";
import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/OctopusRoutingContext";
import { useIsUrlActive, useOctopusLinkComponent } from "../../routing/OctopusRoutingContext";
import { resetStyles } from "../../utils/resetStyles";
import { navigationSideBarItemStyles } from "./navigationSideBarStyles";
interface SharedSideBarTabProps {
    /**
     * The icon for this link.
     */
    icon: React.ReactNode;
    /**
     * The link location.
     */
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    /**
     * The onClick handler associated with the tab.
     */
    onClick?: MouseEventHandler<Element>;
}
interface NavigationSideBarTabIconOnlyProps extends SharedSideBarTabProps {
    /**
     * The required accessible name for this icon link.
     */
    accessibleName: string;
}
interface NavigationSideBarTabWithLabelProps extends SharedSideBarTabProps {
    /**
     * An optional accessible name for this icon link. If no accessible name is provided the label will be used.
     */
    accessibleName?: string;
    /**
     * The label for this icon link.
     */
    label: string;
}
type NavigationSideBarIconLinkProps = NavigationSideBarTabIconOnlyProps | NavigationSideBarTabWithLabelProps;
export function NavigationSideBarTab(props: NavigationSideBarIconLinkProps) {
    if (isNavigationSideBarTabWithLabel(props)) {
        return <NavigationSideBarTabWithLabel icon={props.icon} label={props.label} accessibleName={props.accessibleName} href={props.href} showLinkAsActive={props.showLinkAsActive} onClick={props.onClick}/>;
    }
    return <NavigationSideBarTabIconOnly icon={props.icon} href={props.href} accessibleName={props.accessibleName} showLinkAsActive={props.showLinkAsActive} onClick={props.onClick}/>;
}
function NavigationSideBarTabWithLabel({ icon, label, showLinkAsActive, href, accessibleName, onClick }: NavigationSideBarTabWithLabelProps) {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    return (<Link className={navigationSideBarTabWithLabelStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName} onClick={onClick}>
            {icon}
            {label}
        </Link>);
}
function NavigationSideBarTabIconOnly({ icon, showLinkAsActive, href, accessibleName, onClick }: NavigationSideBarTabIconOnlyProps) {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    return (<Link className={navigationSideBarTabIconOnlyStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName} onClick={onClick}>
            {icon}
        </Link>);
}
function isNavigationSideBarTabWithLabel(item: NavigationSideBarIconLinkProps): item is NavigationSideBarTabWithLabelProps {
    return "label" in item && Boolean(item.label);
}
function buildBorderGradient(color: string, backgroundColor: string) {
    return `linear-gradient(270deg, ${color} 0%, ${color} 4px, ${backgroundColor} 0.1rem, ${backgroundColor} 100%);`;
}
const navigationSideBarTabBaseStyles = css(resetStyles.anchor, {
    "&[aria-current=page]": {
        background: buildBorderGradient(colorScales.blue["400"], "transparent"),
        color: themeTokens.color.text.selected,
        ":hover": {
            background: buildBorderGradient(colorScales.blue["400"], themeTokens.color.background["primary-exp"].hovered),
        },
    },
}, navigationSideBarItemStyles);
const navigationSideBarTabWithLabelStyles = css(navigationSideBarTabBaseStyles, {
    display: "flex",
    flexDirection: "column",
});
const navigationSideBarTabIconOnlyStyles = navigationSideBarTabBaseStyles;
