import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function GitCommitIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 27C22.5 27 24.75 25.6875 26 23.5C27.3125 21.375 27.3125 18.6875 26 16.5C24.75 14.375 22.5 13 20 13C17.4375 13 15.1875 14.375 13.9375 16.5C12.625 18.6875 12.625 21.375 13.9375 23.5C15.1875 25.6875 17.4375 27 20 27ZM29.875 21.5C29.125 26.3125 25 30 20 30C14.9375 30 10.8125 26.3125 10.0625 21.5H1.5C0.625 21.5 0 20.875 0 20C0 19.1875 0.625 18.5 1.5 18.5H10.0625C10.8125 13.6875 14.9375 10 20 10C25 10 29.125 13.6875 29.875 18.5H38.5C39.3125 18.5 40 19.1875 40 20C40 20.875 39.3125 21.5 38.5 21.5H29.875Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
    fill: themeTokens.color.icon.secondary,
});
