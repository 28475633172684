import { css } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
export function CircleInfoIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0312 7C15.3438 7 11.0938 9.5 8.71875 13.5C6.40625 17.5625 6.40625 22.5 8.71875 26.5C11.0938 30.5625 15.3438 33 20.0312 33C24.6562 33 28.9062 30.5625 31.2812 26.5C33.5938 22.5 33.5938 17.5625 31.2812 13.5C28.9062 9.5 24.6562 7 20.0312 7ZM20.0312 36C14.2812 36 9.03125 33 6.15625 28C3.28125 23.0625 3.28125 17 6.15625 12C9.03125 7.0625 14.2812 4 20.0312 4C25.7188 4 30.9688 7.0625 33.8438 12C36.7188 17 36.7188 23.0625 33.8438 28C30.9688 33 25.7188 36 20.0312 36ZM17.5312 25H19.0312V21H17.5312C16.6562 21 16.0312 20.375 16.0312 19.5C16.0312 18.6875 16.6562 18 17.5312 18H20.5312C21.3438 18 22.0312 18.6875 22.0312 19.5V25H22.5312C23.3438 25 24.0312 25.6875 24.0312 26.5C24.0312 27.375 23.3438 28 22.5312 28H17.5312C16.6562 28 16.0312 27.375 16.0312 26.5C16.0312 25.6875 16.6562 25 17.5312 25ZM20.0312 16C18.9062 16 18.0312 15.125 18.0312 14C18.0312 12.9375 18.9062 12 20.0312 12C21.0938 12 22.0312 12.9375 22.0312 14C22.0312 15.125 21.0938 16 20.0312 16Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
});
