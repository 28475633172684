import { css } from "@emotion/css";
import { text, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MouseEventHandler } from "react";
import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing";
import { Divider } from "../Divider/index";
import { NavigationListLink } from "./NavigationListLink";
export interface NavigationListProps {
    listGroups: ReadonlyArray<NavigationListGroup>;
    onLinkClicked?: () => void;
}
export interface NavigationListGroup {
    heading: string | undefined;
    items: ReadonlyArray<NavigationListGroupItem>;
}
export type NavigationListGroupItem = GroupSpacer | NavigationListLinkItem;
type GroupSpacer = "spacer";
interface NavigationListLinkItem {
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    label: string;
    accessibleName?: string;
    onClick?: MouseEventHandler<Element>;
}
export function NavigationList({ listGroups, onLinkClicked }: NavigationListProps) {
    return (<nav className={navigationListStyles}>
            {listGroups.map((group, groupIndex) => group.items.length > 0 && (<div key={group.heading ?? groupIndex}>
                            {group.heading && <div className={listGroupTitleStyles}>{group.heading}</div>}
                            {group.items.map((item: NavigationListGroupItem, index) => (<NavigationListItem key={index} item={item} onLinkClicked={onLinkClicked}/>))}
                            {groupIndex !== listGroups.length - 1 && (<div className={groupDividerStyles}>
                                    <Divider />
                                </div>)}
                        </div>))}
        </nav>);
}
function NavigationListItem({ item, onLinkClicked }: {
    item: NavigationListGroupItem;
    onLinkClicked?: () => void;
}) {
    if (isGroupSpacer(item)) {
        return <div className={spacerStyles}></div>;
    }
    else {
        const onClick = (ev: React.MouseEvent<Element>) => {
            onLinkClicked?.();
            item.onClick?.(ev);
        };
        return <NavigationListLink label={item.label} href={item.href} showLinkAsActive={item.showLinkAsActive} accessibleName={item.accessibleName} onClick={onClick}/>;
    }
}
function isGroupSpacer(item: NavigationListGroupItem): item is GroupSpacer {
    return item === "spacer";
}
const navigationListStyles = css({
    width: "12.5rem",
    height: "100%",
    padding: `${space["24"]} ${space["16"]}`,
    background: themeTokens.color.background.primary.transparent,
    overflowY: "auto",
    // to hide the scrollbar on the right side of the panel, while scrolling is enabled
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
    },
});
const listGroupTitleStyles = css({
    font: text.interface.body.bold.xSmall,
    color: themeTokens.color.text.tertiary,
    textTransform: "uppercase",
    padding: `${space["4"]} ${space["8"]}`,
});
const spacerStyles = css({
    height: "1rem",
});
const groupDividerStyles = css({
    margin: `${space["24"]} 0`,
});
