import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function LockIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12V16H25V12C25 9.25 22.75 7 20 7C17.1875 7 15 9.25 15 12ZM12 16V12C12 7.625 15.5625 4 20 4C24.375 4 28 7.625 28 12V16H30C32.1875 16 34 17.8125 34 20V32C34 34.25 32.1875 36 30 36H10C7.75 36 6 34.25 6 32V20C6 17.8125 7.75 16 10 16H12ZM9 20V32C9 32.5625 9.4375 33 10 33H30C30.5 33 31 32.5625 31 32V20C31 19.5 30.5 19 30 19H10C9.4375 19 9 19.5 9 20Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
    fill: themeTokens.color.icon.secondary,
});
