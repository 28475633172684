import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MouseEventHandler } from "react";
import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/index";
import { useIsUrlActive, useOctopusLinkComponent } from "../../routing/index";
interface NavigationListLinkProps {
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    label: string;
    accessibleName?: string;
    onClick?: MouseEventHandler<Element>;
}
export function NavigationListLink({ href, showLinkAsActive, label, accessibleName, onClick }: NavigationListLinkProps) {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    return (<Link className={navigationListLinkStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName} onClick={onClick}>
            {label}
        </Link>);
}
const navigationListLinkStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["8"],
    textDecoration: "none",
    font: text.interface.body.default.medium,
    color: themeTokens.color.navList.text.default,
    backgroundColor: themeTokens.color.navList.background.default,
    padding: `${space["4"]} ${space["8"]}`,
    borderRadius: space["4"],
    ":hover": {
        color: themeTokens.color.navList.text.hover,
        backgroundColor: themeTokens.color.navList.background.hover,
    },
    ":active": {
        color: themeTokens.color.navList.text.active,
        background: themeTokens.color.navList.background.active,
    },
    "&[aria-current=page]": {
        backgroundColor: themeTokens.color.navList.background.active,
        color: themeTokens.color.navList.text.active,
    },
});
